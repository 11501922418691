import { Injectable, inject } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { ApiAbstractService } from '../../../../shared/services/api-abstract.service';
import { Subscription } from '../../../../shared/types/subscription.type';
import { UserRepository } from '../../../users/shared/repositories/user.repository';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService extends ApiAbstractService<Subscription> {
  endpoint = 'subscriptions';

  private readonly userRepository: UserRepository = inject(UserRepository);

  checkCompanySubscription(): Observable<boolean> {
    const companyId = this.userRepository.user.company?.id;
    if (!companyId) {
      return of(false);
    }

    return this.get(`check/${companyId}`).pipe(map((res: unknown) => !!res));
  }

  refreshSubscription(id: string): Observable<Subscription | null> {
    return this.httpClient
      .post(this.getResourceUrl(`${id}/refresh`), {})
      .pipe(map((res: unknown) => (res ? (res as Subscription) : null)));
  }

  renewSubscription(): Observable<boolean> {
    const companyId = this.userRepository.user.company?.id;
    if (!companyId) {
      return of(false);
    }

    const url = `renew/${companyId}`;
    return this.get(url).pipe(map((res: unknown) => !!res));
  }

  cancel(id: string, cancelNow = false): Observable<boolean> {
    return this.httpClient
      .post(this.getResourceUrl(`${id}/cancel${cancelNow ? '/1' : ''}`), {})
      .pipe(map((res: unknown) => !!res));
  }
}
