import { Injectable, inject } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { StatsQueryParamsDto } from '../../../../shared/dto/stats-query-params.dto';
import { ApiAbstractService } from '../../../../shared/services/api-abstract.service';
import { ListCount } from '../../../../shared/types/list-count.type';
import { User } from '../../../../shared/types/user.type';
import { tzDate } from '../../../../shared/utils/date.utils';
import { httpClientParamsSerializer } from '../../../../shared/utils/http-client-params-serializer';
import { CheckCustomerAccountResponseDto } from '../dto';
import { UserRepository } from '../repositories/user.repository';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends ApiAbstractService<User> {
  override endpoint = 'users';

  private readonly userRepository: UserRepository = inject(UserRepository);

  override fromServerModel(json: unknown): User {
    const user = json as User;
    return { ...user, createdAt: tzDate(user.createdAt).toDate() } as User;
  }

  acceptCgu(): Observable<boolean> {
    const userId = this.userRepository.user?.id;
    if (!userId) {
      return of(false);
    }

    return this.update(userId, { cgu: true }).pipe(
      // On vérifie que le champ CGU est bien passé à true
      map((res: unknown | null) => Object.hasOwnProperty.call(res, 'cgu') && !!(res as User).cgu),
    );
  }

  checkCustomerAccount(): Observable<CheckCustomerAccountResponseDto> {
    return this.httpClient.get<CheckCustomerAccountResponseDto>(this.getResourceUrl('customer/check'));
  }

  getListCount(params: StatsQueryParamsDto): Observable<ListCount> {
    return this.httpClient.get<ListCount>(this.getResourceUrl('count'), {
      params: httpClientParamsSerializer(params),
    });
  }
}
